.missionEdite{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    background: #fff;
    padding: 20px;
    overflow: none;
    .contrat-wrapper{
        width: 100%;
        .edit-title{
            .edit-h2{
                font-size: 1.9rem;
                padding-bottom: 20px;
                color: #444444;
                font-weight: 300;
            }
        }
        .formulaire-edit{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            .edit-rows{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                width: 100%;
                .edit-row{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;
                    .label-edit{
                        width: 100%;
                        font-size: .8rem;
                        color: #555;
                        display: flex;
                        align-items: center;
                        span{
                            color: red;
                            font-size: 1.1rem;
                        }
                    }
                    .input-form{
                        border: 1px solid #d1d0d0;
                        width: 100%;
                        padding: 10px;
                        width: 100%;
                        border-radius: 5px;
                        background: #f2f2f269;
                        outline: none;
                        &:focus{
                            border: 1px solid rgba(58, 88, 180, 0.781);
                        }
                    }
                }
            }
            .edit-btn{
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                font-size: 1rem;
                background: royalblue;
                border-radius: 5px;
                color: #f2f2f2;
                cursor: pointer;
                transition: .5s;
                padding: 10px 15px;
                width: max-content;
                &:hover{
                    background: rgba(54, 85, 179, 0.979);
                }
                .form-icon{
                    font-size: 1rem;
                }
            }
        }
    }
}