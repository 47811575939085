.views{
    background: #fff;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    .views-wrapper{
        width: 100%;
        .views-title{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            background: #fdfdfd;
            padding: 10px;
            .h1-views{
                text-align: center;
                font-size: 1.5rem;
                font-weight: 400;
                position: relative;
            }
            .views-bar{
                background: #555;
                display: block;
                width: 150px;
                height: 2px;
            }
        }
        .views-rows-items{
            border: 1px solid #ebe9e9;
            background: #fdfdfd;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            padding: 40px;
            gap: 20px;
            border-radius: 5px;
            margin: 0 auto;

            .views-container{
                width: 100%;
                margin: 0 auto;
                padding: 10px 0;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .views-ctn1{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    .views-cont2{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        .views-rows{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-left: 20px;
                        }
                    }
                    .views-photo{
                        height: 250px;
                        width: 200px;
                        object-fit: cover;
                        border: 1px solid #f0efef;
                        border-radius: 5px;
                    }
                }
                .view-h2{
                    font-size: 1.3rem;
                    font-weight: 300;
                    span{
                        color: red;
                    }
                }
                .views-rows{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 40px;
                    border-bottom: 1px solid #e0dcdc;
                    .views-left{
                        flex: 1;
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        .view-label{
                            font-weight: 600;
                            padding: 10px 0;
                            font-size: .9rem;
                            color: #555;
                            display: flex;
                            align-items: center;
                            .icon-person{
                                margin-right: 10px;
                            }
                        }
                        .view-result{
                            font-size: .9rem;
                            color: #555;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .views-right{
                        flex: 1;
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        .btn-edite{
                            display: flex;
                            align-items: center;
                            background: #3bb077;
                            color: #fff;
                            padding: 5px 15px;
                            font-size: .8rem;
                            border-radius: 5px;
                            transition: .5s;
                            &:hover{
                                background: green;
                            }
                            .client-btn-icon{
                                font-size: 1.2rem;
                                padding-right: 5px;
                            }
                        }
                        .view-label{
                            font-weight: 600;
                            padding: 10px 0;
                            font-size: .9rem;
                            color: #555;
                            display: flex;
                            align-items: center;
                            .icon-person{
                                margin-right: 10px;
                            }
                        }
                        .view-result{
                            font-size: .9rem;
                            color: #555;
                        }
                    }
                }
            }
        }
    }
    
}