.featedList{
    position: relative;
    flex: .800;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    overflow: hidden;
    border: 1px solid rgba(6, 102, 247, 0.144);
    height: 100%;
/*     .title-h2{
        font-size: .9rem;
        margin-bottom: 10px;
        font-weight: 500;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        background: rgba(6, 102, 247, 0.514);
        height: 40px;
        width: max-content;
        padding: 0 10px;
        color: #fff;
        border-radius: 20% 30%;
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        .icon-title{
            padding-right: 5px;
            color: #fff;
            font-size: 1.7rem;
        }
    } */
    .titles-h2{
        font-size: 1rem;
        color: #555;
        font-weight: 300;
        margin-bottom: 20px;
    }
    hr{
        border: none;
        height: 1px;
        width: 100%;
        background: #e7e7e7;
    }
    .feated-container{
        border-radius: 10px;
        .table-icons-row{
            display: flex;
            align-items: center;
            gap: 10px;
            .userEye{
                color:rgba(6, 102, 247, 0.514);
                font-size: 1rem;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }
            .userListBtn{
                color: #3bb077;
                cursor: pointer;
                font-size: 1rem;
                &:hover{
                    transform: scale(1.1);
                }
            }
            .userListDelete{
                color: red;
                font-size: 1rem;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }

/*         table{
            border-collapse :collapse;
            width : 100%;
            border: 1px solid #e7e5e59d;
            border-radius: 10px;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            tr{
                th{
                    font-weight: 300;
                    font-size: .9rem;
                    border: 1px solid rgba(6, 102, 247, 0.144);
                    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    text-align : left;
                    padding : 15px;
                    border-radius: 5px;
                    background: #f7f6f6;
                }
                td{
                    font-size: .8rem;
                    text-align : left;
                    color: #555;
                    padding : 10px;
                    border-bottom: 1px solid #adacac7e;
                }
                .item-action{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .icon-oeil{
                        color: rgb(21, 92, 21);
                    }
                    .icon-info{
                        color: rgb(100, 100, 233);
                    }
                }
            }
        } */

    }
}
