.paiementAgent{
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    .facturation-wrapper{
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-radius: 5px;
        .contrats-top{
            display: flex;
            align-items: center;
            gap: 10px;
            .contrats-icon{
                font-size: 3rem;
                background: #fff;
                padding: 10px;
                color: royalblue;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            }
            .contrats-info{
                .contrats-title{
                    font-size: 1rem;
                }
                .contrats-span{
                    font-size: .7rem;
                    font-weight: 300;
                }
            }
        }
        .personnel-btn{
            display: flex;
            align-items: center;
            gap: 5px;
            border: none;
            padding: 6px 10px;
            border-radius: 5px;
            border: 1px solid royalblue;
            background: #fff;
            cursor: pointer;
            color: royalblue;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        }
    }
    .table-icons-row{
        display: flex;
        gap: 10px;
        .userListBtn{
            border: none;
            color: #3bb077;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userEye{
            color: rgba(21, 50, 136, 0.801);
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userListDelete{
            color: red;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .personnel-aff{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .presenceTable{
            flex: 1;
            background: #fff;
            padding: 5px;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        }
        .personnel-btn{
            background: royalblue;
            border: none;
            color: #fff;
            width: max-content;
            padding: 10px 15px;
            border-radius: 5px;
            cursor: pointer;
            transition: .5s;
            &:hover{
                background: rgb(19, 30, 65);
            }
        }
        .personnel-aff-bottom{
            flex: 1;
            padding: 20px;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            background: #fff;
            display: flex;
            flex-direction: column;
            .paiement-row-select{
                display: flex;
                align-items: center;
                background: #fafafa;
                gap: 10px;
                margin-bottom: 20px;
                padding: 20px;
                border-radius: 5px;
                border: 1px solid #d4d4d4;
                label{
                    color: #555;
                    font-size: .9rem;
                    span{
                        color: red;
                    }
                }
                select{
                    border: 1px solid #e2e0e0;
                    padding: 5px 10px;
                    border-radius: 5px;
                    outline: none;
                    &:focus{
                        border: 1px solid rgba(36, 86, 236, 0.63)
                    }
                }
            }
            .personnel-row{
                display: flex;
                flex-direction: column;
                gap: 10px;
                box-shadow: 0px 0px 10px -9px rgba(0,0,0,0.75);
                gap: 8px;
                width: 350px;
                background: #fcfbfb;
                padding: 20px;
                border-radius: 5px;
                border: 1px solid #d4d4d4;
                .personnel-icons{
                    font-size: 5rem;
                    color: #555;
                }
                .personnel-total-title{
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #555;
                    font-weight: 500;
                    font-size: 1.2rem;
                }
                hr{
                    height: 1px;
                    width: 100%;
                    background: #afafaf;
                    border: none;
                    margin: 5px 0;
                }
                .personnel-paiement-row{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .paiement-name{
                        font-size: .9rem;
                    }
                    .paiement-name1-input{
                        width: 80px;
                        border: none;
                        outline: none;
                        background: #3bb077;
                        color: #fff;
                    }
                    .paiement-name1{
                        background: #3bb077;
                        color: #fff;
                        font-size: .8rem;
                        padding: 5px 10px;
                        border-radius: 5px;
                    }
                    .label-form{
                        font-size: .9rem;
                    }
                    .btn-paiement{
                        padding: 8px 15px;
                        border: none;
                        color: #fff;
                        background: royalblue;
                        border-radius: 5px;
                        cursor: pointer;
                        width: 100%;
                    }
                }
            }
            .personnel-row2{
                display: flex;
                justify-content: space-between;
                gap: 10px;
                box-shadow: 0px 0px 10px -9px rgba(0,0,0,0.75);
                gap: 8px;
                background: #fafafa;
                padding: 20px;
                border-radius: 5px;
                .personnel-money{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                }
                .personnel-icons{
                    font-size: 5rem;
                    background: #fff;
                    padding: 10px;
                    color: royalblue;
                    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                    border-radius: 10px;
                    &:hover{
                        transform: scale(1.1);
                    }
                }
                .personnel-title-icon{
                    color: royalblue;
                    font-weight: 800;
                }
            }
        }
    }
    
}