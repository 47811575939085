.topbar{
    height: 60px;
    width: 100%;
    position: sticky;
    padding: 20px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 111;
    top: 0;
    border-bottom: 1px solid #e6e5e5;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    .topbar-left{

        .topbar-img-title{
            width: 40px;
            height: 40px;
            cursor: pointer;
            color: rgb(73, 73, 73);
        }
    }
    .topbar-right{
        display: flex;
        align-items: center;
        gap: 10px;
        .topbar-icon{
            cursor: pointer;
            font-size: 1.5rem;
            color: rgb(114, 114, 114);
        }  
        .topbar-img{
            height: 25px;
            width: 25px;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
        }
        .topbar-row{
            display: flex;
            align-items: center;
            gap: 5px;
            .topbar-power{
                font-size: 1rem;
                cursor: pointer;
            }
            .span-logout{
                cursor: pointer;
            }
        }
    }
}