.rapportRow{
    background: #fff;
    padding: 20px;
    .rapportRow-wrapper{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .rapportRow-h2{
            color: #555;
            font-weight: 300;
            font-size: 1.1rem;
        }
        .rapport-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            .rapport-input{
                position: relative;
                flex: 1;
                border: 1px solid #e2e2e2;
                padding: auto 8px;
                width: 100%;
                border-radius: 5px;
                outline: none;
            }
            .rapport-select{
                width: 70%;
            }
            .rapport-btn{
                border: none;
                width: 20%;
                padding: 8px;
                background: rgb(70, 97, 179);
                color: #fff;
                border-radius: 5px;
                cursor: pointer;
                transition: .5s;
                &:hover{
                    background: rgb(17, 40, 107);
                }
            }
        }
    }
}