.facturation{
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    .facturation-wrapper{
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-radius: 5px;
        .contrats-top{
            display: flex;
            align-items: center;
            gap: 10px;
            .contrats-icon{
                font-size: 3rem;
                background: #fff;
                padding: 10px;
                color: royalblue;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            }
            .contrats-info{
                .contrats-title{
                    font-size: 1rem;
                }
                .contrats-span{
                    font-size: .7rem;
                    font-weight: 300;
                }
            }
        }
        .personPdf{
            display: flex;
            gap: 10px;
            .personnel-btn{
                display: flex;
                align-items: center;
                gap: 5px;
                border: none;
                padding: 6px 10px;
                border-radius: 5px;
                border: 1px solid royalblue;
                background: #fff;
                cursor: pointer;
                color: royalblue;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                transition: .5s;
                &:hover{
                    background: royalblue;
                    color: #fff;
                }
            }
            .personnel-btn-pdf{
                display: flex;
                align-items: center;
                gap: 5px;
                border: none;
                padding: 6px 10px;
                border-radius: 5px;
                border: 1px solid red;
                background: #fff;
                cursor: pointer;
                color: red;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                transition: .5s;
                &:hover{
                    background: rgb(112, 4, 4);
                    color: #fff;
                }
            }
            .personnel-btn-excel{
                display: flex;
                align-items: center;
                gap: 5px;
                border: none;
                padding: 6px 10px;
                border-radius: 5px;
                border: 1px solid green;
                background: #fff;
                cursor: pointer;
                color: green;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                transition: .5s;
                &:hover{
                    background: green;
                    color: #fff;
                }
            }
        }
    }
    .cell-paid {
        border: 1px solid green;
        background-color: rgba(4, 180, 4, 0.555);
        color: white;
        padding: 2px 8px;
        border-radius: 10px;
        cursor: pointer;
        font-size: .8rem;
      }
      
      .cell-not-paid {
        border: 1px solid red;
        background-color: rgba(255, 0, 0, 0.521);
        color: white;
        padding: 2px 8px;
        border-radius: 10px;
        cursor: pointer;
        font-size: .8rem;
      }
    .table-icons-row{
        display: flex;
        gap: 10px;
        .userListBtn{
            border: none;
            color: #3bb077;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userOvert0{
            position: relative;
            &:hover .userOvert{
                transform: scale(1.1);
                opacity: 1.1;
            }
            .userOvert{
                color: #3bb077;
                position: absolute;
                top: -15px;
                left: -10px;
                right: 0;
                opacity: 0;
            }
        }
        .userOvert1{
            position: relative;
            &:hover .userOvert{
                transform: scale(1.1);
                opacity: 1.1;
            }
            .userEye{
                color: rgba(21, 50, 136, 0.801);
                cursor: pointer;
            }
            .userOvert{
                color: royalblue;
                position: absolute;
                top: -15px;
                left: 0;
                right: 0;
                opacity: 0;
            }
        }
        .userOvert2{
            position: relative;
            &:hover .userOvert{
                transform: scale(1.1);
                opacity: 1.1;
            }
            .userListDelete{
                color: red;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }
            .userOvert{
                color: red;
                position: absolute;
                top: -15px;
                left: -25px;
                right: 0;
                opacity: 0;
            }
        }
        .userOvert3{
            position: relative;
            &:hover .userOvert{
                transform: scale(1.1);
                opacity: 1.1;
            }
            .userListAjout{
                color: royalblue;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }
            .userOvert{
                color: royalblue;
                position: absolute;
                top: -15px;
                left: -10px;
                right: 0;
                opacity: 0;
            }
        }
        .userEye{
            color: rgba(21, 50, 136, 0.801);
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userListDelete{
            color: red;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .presenceTable{
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    }
    .personnel-btn{
        background: royalblue;
        border: none;
        color: #fff;
        width: max-content;
        padding: 10px 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: .5s;
        &:hover{
            background: rgb(19, 30, 65);
        }
    }
}