.paiement{
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    background: #fff;
    .paiement-wrapper{
        padding: 50px;
        width: 100%;
        .paiement-tete{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .paiement-logo{
                width: 130px;
                height: 130px;
            }
        }
        .paiement-row-title{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 40px 0px;
            .span-paiement-color{
                flex: 1.3;
                display: block;
                background: #253053;
                width: 100%;
                height: 40px;
            }
            .paiement-h2{
                flex: 2;
                text-align: center;
                font-size: 2rem;
                color: #253053;
            }
        }
        .paiement-form{
            display: flex;
            flex-direction: column;
            gap: 20px;
            .paiement-form-row{
                display: flex;
                align-items: center;
                gap: 30px;
                .paiement-h3{
                    font-size: 1.6rem;
                    color: #253053;
                }
                .paiement-span{
                    flex: 1;
                    color: #253053;
                }
            }
        }
    }
}