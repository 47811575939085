.presenceList{
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    .facturation-wrapper{
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-radius: 5px;
        .contrats-top{
            display: flex;
            align-items: center;
            gap: 10px;
            .contrats-icon{
                font-size: 3rem;
                background: #fff;
                padding: 10px;
                color: royalblue;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            }
            .contrats-info{
                .contrats-title{
                    font-size: 1rem;
                }
                .contrats-span{
                    font-size: .7rem;
                    font-weight: 300;
                }
            }
        }
        .personnel-btn{
            display: flex;
            align-items: center;
            gap: 5px;
            border: none;
            padding: 6px 10px;
            border-radius: 5px;
            border: 1px solid royalblue;
            background: #fff;
            cursor: pointer;
            color: royalblue;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        }
    }
    .table-icons-row{
        display: flex;
        gap: 10px;
        .userListBtn{
            border: none;
            color: #3bb077;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userEye{
            color: rgba(21, 50, 136, 0.801);
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userListDelete{
            color: red;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .personnel-aff{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .presenceTable{
            flex: 1;
            background: #fff;
            padding: 5px;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        }
        .personnel-btn{
            background: royalblue;
            border: none;
            color: #fff;
            width: max-content;
            padding: 10px 15px;
            border-radius: 5px;
            cursor: pointer;
            transition: .5s;
            &:hover{
                background: rgb(19, 30, 65);
            }
        }
        .presentList-aff-bottom{
            flex: 1;
            padding: 20px;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            background: #fff;
            display: flex;
            justify-content: center;
            .personnel-row{
                display: flex;
                flex-direction: column;
                box-shadow: 0px 0px 10px -9px rgba(0,0,0,0.75);
                gap: 10px;
                width: 400px;
                background: #fafafa;
                height: max-content;
                padding: 30px;
                border-radius: 5px;
                .personnel-h2{
                    margin: 0 auto;
                    color: #555;
                    font-size: 1.4rem;
                    font-weight: 500;
                    padding: 10px 0;
                    text-align: center;
                    border-bottom: 1px solid #cacaca;
                    width: max-content;
                }
                .presence-control{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    label{
                        color: #555;
                        font-size: .899rem;
                        span{
                            color: red;
                        }
                    }
                    .presence-rows-heure{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 20px;
                        .presence-rows{
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            label{
                                color: #555;
                                font-size: .899rem;
                                span{
                                    color: red;
                                }
                            }
                            .presence-input{
                                border: 1px solid #e0e0e0;
                                padding: 8px 20px;
                                outline: rgba(199, 199, 235, 0.486);
                                border-radius: 10px;
                            }
                        }
                    }
                }
                .presence-btn{
                    margin-top: 10px;
                    border: none;
                    padding: 10px 15px;
                    background: royalblue;
                    color: #fff;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: .6s;
                    &:hover{
                        background: rgb(28, 68, 187);
                    }
                }
            }
        }
    }
    
}