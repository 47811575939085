.featedInfo{
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    .feated-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .feated-title{
            font-size: .8rem;
            margin-bottom: 10px;
            font-weight: 500;
            padding-bottom: 5px;
            color:#fff;
            width: max-content;
            background: rgba(6, 102, 247, 0.514);
            height: 35px;
            width: 35px;
            border-radius: 50% 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            cursor: pointer;
        }

    }
    .feated-rows{
        display: flex;
        gap: 10px;

        .feated-row{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 15px 15px 10px;
            border-radius: 10px;
            cursor: pointer;
            border: 1px solid rgba(6, 102, 247, 0.144);
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            .feated-top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .feated-row-title{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    .feated-h2{
                        font-weight: 300;
                        font-size: 1rem;
                        color: #253053;
                    }
                    .feated-span{
                        font-weight: 900;
                        font-size: 2rem;
                        color: #253053;
                    }
                }
                .feated-right{
                    background: rgba(6, 102, 247, 0.514);
                    height: 50px;
                    width: 50px;
                    border-radius: 50% 15%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .feated-icon{
                        color:#fff;
                        font-size: 2rem;
                    }
                }
            }
            .feated-bottom{
                span{
                    color :rgba(6, 102, 247, 0.514);
                    font-size: 1.5rem;
                }
            }
        }
    }
}