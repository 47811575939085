.contrat-View{
    background: #fff;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    border-radius: 5px;
    .clientView-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .client-title{
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            margin-bottom: 20px;
            padding: 10px 15px;
            font-size: 1.1rem;
            font-weight: 300;
            color: #555;
            background: #fdfdfd;
            border: 1px solid #ebe9e9;
            border-radius: 5px;
        }
        .client-rows{
            border: 1px solid #ebe9e9;
            padding: 40px;
            width: 80%;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 50px;
            background: #fdfdfd;
            border-radius: 5px;
            .client-row-title{
                font-size: 1.1rem;
                border-radius: 5px;
                padding: 10px 15px;
                color: #555;
                font-weight: 200;
                background: #fff;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.637);
                box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.61);
                width: max-content;
                border: 1px solid #ebe9e9;
                margin-left: 10px;
                span{
                    color: red;
                }
            }
            .client-row{
                display: flex;
                align-items: center;
                padding: 10px 10px;
                gap: 20px;
                border-bottom: 1px solid #e4e2e2;
                .client-nom{
                    display: flex;
                    align-items: center;
                    font-size: 1rem;
                    gap: 10px;
                    font-weight: 300;
                    color: #555;
                }

            }
            .clientR-right{
                .btn-edite{
                    display: flex;
                    width: max-content;
                    align-items: center;
                    background: #3bb077;
                    color: #fff;
                    padding: 5px 15px;
                    font-size: .7rem;
                    border-radius: 5px;
                    transition: .5s;
                    .client-btn-icon{
                        font-size: 1.2rem;
                        padding-right: 5px;
                    }
                    &:hover{
                        background: green;
                    }
                }
            }
        }
    }
}