.affEdite{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    padding: 30px;
    background: #fff;
    .client-h2 {
        font-size: 1.4rem;
        color: #383636;
        padding-bottom: 10px;
        font-weight: 600;
    }
    .clientForm-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
        .form-center{
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            .form-rows{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                .form-row{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;
                    .label-form{
                        width: 100%;
                        font-size: .8rem;
                        color: #555;
                        display: flex;
                        align-items: center;
                        span{
                            color: red;
                            font-size: 1.1rem;
                        }
                    }
                    .input-form{
                        border: 1px solid #d1d0d0;
                        width: 100%;
                        padding: 8px 10px;
                        width: 100%;
                        border-radius: 5px;
                        background: #f2f2f269;
                        outline: none;
                        &:focus{
                            border: 1px solid rgba(58, 88, 180, 0.781);
                        }
                    }
                }
            }
            .form-btn{
                width: max-content;
                padding: 10px 15px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                font-size: 1rem;
                background: royalblue;
                border-radius: 5px;
                color: #f2f2f2;
                cursor: pointer;
                transition: .5s;
                &:hover{
                    background: rgba(54, 85, 179, 0.979);
                }
                .form-icon{
                    font-size: 1rem;
                }

            }
        }
    }
}