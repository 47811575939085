.facturation{
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    .facturation-wrapper{
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-radius: 5px;
        .contrats-top{
            display: flex;
            align-items: center;
            gap: 10px;
            .contrats-icon{
                font-size: 3rem;
                background: #fff;
                padding: 10px;
                color: royalblue;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            }
            .contrats-info{
                .contrats-title{
                    font-size: 1rem;
                }
                .contrats-span{
                    font-size: .7rem;
                    font-weight: 300;
                }
            }
        }
        .personnel-btn{
            display: flex;
            align-items: center;
            gap: 5px;
            border: none;
            padding: 6px 10px;
            border-radius: 5px;
            border: 1px solid royalblue;
            background: #fff;
            cursor: pointer;
            color: royalblue;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        }
    }
    .table-icons-row{
        display: flex;
        gap: 10px;
        .userListBtn{
            border: none;
            color: #3bb077;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userEye{
            color: rgba(21, 50, 136, 0.801);
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userListDelete{
            color: red;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .add-rows{
        display: flex;
        gap: 20px;
        width: 100%;
        .add-row1{
            flex: 2;
            width: 100%;
            position: relative;
            .disponible {
                color: green;
                width: 20px;
                display: flex;
                gap: 5px;
              }
              
            .indisponible {
                color: red;
                display: flex;
                gap: 5px;
              }
            .presenceTable{
                background: #fff;
                padding: 10px;
                border-radius: 5px;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                .disponible {
                    color: green;
                    width: 20px;
                    height: 10px;
                  }
                  
                .indisponible {
                    color: red;
                  }
            }
        }
        .add-row2{
            height: max-content;
            flex: 1;
            background: #fff;
            border-radius: 5px;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            display: flex;
            flex-direction: column;
            padding: 20px;
            gap: 40px;
            .add-container-rows{
                .add-row-top{
                    h2{
                        font-size: 1rem;
                        color: rgb(48, 48, 48);
                        padding: 20px 0;
                    }
                    .input-row{
                        label{
                            font-size: .9rem;
                            color: #555;
                            .info-name{
                                background: #f8f8f8;
                                display: flex;
                                align-items: center;
                                gap: 7px;
                                padding: 8px;
                                .input-radio{
                                    padding-right: 8px;
                                    outline: rgb(3, 3, 228);
                                }
                            }
                            .label-info{
                                font-size: .9rem;
                                color: black;
                                padding-left: 20px;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                                .label-info-row{
                                    .salaire-input{
                                        border: none;
                                        height: 25px;
                                        width: 80px;
                                        padding: 4px;
                                        outline: none;
                                        border-radius: 5px;
                                        &:focus{
                                            border: 1px solid rgba(95, 131, 238, 0.815);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .rows-btn{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 20px 0;
                        button{
                            border: none;
                            padding: 10px 15px;
                            color: #fff;
                            background: rgb(2, 2, 223);
                            border-radius: 5px;
                            cursor: pointer;
                            &:hover{
                                background: rgb(3, 3, 114);
                            }
                        }
                        .ajouter{
                            background: rgb(2, 92, 2);
                            &:hover{
                                background: green;
                            }
                        }
                    }
                }
                .add-row-bottom{
                    padding-top: 20px;
                    .add-row-bottom1{
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        .add-row-h2{
                            font-size: 1.2rem;
                            color: #2e2e2e;
                        }
                        .add-row-span{
                            font-size: .9rem;
                        }
                    }      
                    .add-row-btn{
                        padding: 10px 15px;
                        color:#fff;
                        background: royalblue;
                        border-radius: 5px;
                        border: none;
                        margin-top: 20px;
                        cursor: pointer;
                        transition: .5s;
                        &:hover{
                            background: rgb(12, 38, 116);
                        }
                    }
                }
            }

        }
    }
}