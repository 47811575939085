.employeeChart{
    flex: 2;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    border: 1px solid rgba(6, 102, 247, 0.144);
    .employeeH2{
        font-size: 1rem;
        color: #555;
        font-weight: 300;
        margin-bottom: 20px;
    }
    hr{
        border: none;
        height: 1px;
        width: 100%;
        background: #e7e7e7;
    }
}