.personnel{
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    .personnel-rows{
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-radius: 5px;
        .personnel-top{
            display: flex;
            align-items: center;
            gap: 10px;
            .personnel-icon{
                font-size: 3rem;
                background: #fff;
                padding: 10px;
                color: royalblue;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            }
            .personnel-info{
                .personnel-title{
                    font-size: 1rem;
                }
                .personnel-span{
                    font-size: .7rem;
                    font-weight: 300;
                }
            }
        }
        .personPdf{
            display: flex;
            gap: 10px;
            .personnel-btn-pdf{
                display: flex;
                align-items: center;
                gap: 5px;
                border: none;
                padding: 6px 10px;
                border-radius: 5px;
                border: 1px solid red;
                background: #fff;
                cursor: pointer;
                color: red;
                -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
                transition: .5s;
                &:hover{
                    background: red;
                    color: #fff;
                }
            }
        }
        .personnel-btn-excel{
            display: flex;
            align-items: center;
            gap: 5px;
            border: none;
            padding: 6px 10px;
            border-radius: 5px;
            border: 1px solid green;
            background: #fff;
            cursor: pointer;
            color: green;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            transition: .5s;
            &:hover{
                background: green;
                color: #fff;
            }
        }
        .personnel-btn{
            display: flex;
            align-items: center;
            gap: 5px;
            border: none;
            padding: 6px 10px;
            border-radius: 5px;
            border: 1px solid royalblue;
            background: #fff;
            cursor: pointer;
            color: royalblue;
            -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            transition: .5s;
            &:hover{
                background: royalblue;
                color: #fff;
            }
        }
    }
    .userList{
        display: flex;
        align-items: center;
        gap: 5px;
        object-fit: cover;
        .userImg{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .table-icons-row{
        display: flex;
        gap: 10px;
        .userOvert0{
            position: relative;
            &:hover .userOvert{
                transform: scale(1.1);
                opacity: 1.1;
            }
            .userOvert{
                color: #3bb077;
                position: absolute;
                top: -15px;
                left: -10px;
                right: 0;
                opacity: 0;
            }
        }
        .userOvert1{
            position: relative;
            &:hover .userOvert{
                transform: scale(1.1);
                opacity: 1.1;
            }
            .userEye{
                color: rgba(21, 50, 136, 0.801);
                cursor: pointer;
            }
            .userOvert{
                color: royalblue;
                position: absolute;
                top: -15px;
                left: 0;
                right: 0;
                opacity: 0;
            }
        }
        .userOvert2{
            position: relative;
            &:hover .userOvert{
                transform: scale(1.1);
                opacity: 1.1;
            }
            .userListDelete{
                color: red;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }
            .userOvert{
                color: red;
                position: absolute;
                top: -15px;
                left: -25px;
                right: 0;
                opacity: 0;
            }
        }
        .userListBtn{
            border: none;
            color: #3bb077;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userEye{
            color: rgba(21, 50, 136, 0.801);
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
        .userListDelete{
            color: red;
            cursor: pointer;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
      }
      .bread{
        background: #fff;
        padding: 10px 20px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bread-search{
            display: flex;
            align-items: center;
            gap: 10px;
            .breader-search{
                font-size: 1rem;
                color: #555;
            }
            .input-search{
                border: 1px solid #e0e0e0;
                padding: 8px 10px;
                border-radius: 5px;
                outline: none;
                &:focus{
                    border: 1px solid rgb(115, 149, 248);
                }
            }
        }
        .bread-corbeille{
            cursor: pointer;
            border-radius: 5px;
            padding: 5px 10px;
            border: 1px solid rgba(206, 2, 2, 0.76);
            display: flex;
            align-items: center;
            color: rgba(206, 2, 2, 0.76);
            transition: .5s;
            &:hover{
                background: rgba(206, 2, 2, 0.76);
                color: #f5f5f5;
            }
        }
      }
    .userTable{
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    }
}