.edit-person{
    background: #fff;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    .formulaire-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        gap: 20px;
        .formulaire-left{
            position: relative;
            flex: 1.3;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .form-img{
                height: 250px;
                max-width: 250px;
                object-fit: cover;
            }
            .form-img2{
                max-width: 100%;
                max-height: 280px;
                object-fit: cover;
            }
            .form-img3{
                display: none;
                max-width: 100%;
                max-height: 300px;
            }
            .photo-modifier{
                margin-top: 10px;
                display: flex;
                align-items: center;
                gap: 5px;
                .photoText{
                    font-size: .9rem;
                    color: #5a5959;
                }
            }
            .form-icon{
                font-size: 29px;
                color: #1890ff;
                cursor: pointer;
            }
            .form-img-rows{
                padding-left: 20px;
                .radio-img{
                    outline: none;
                }
               .form-img-row{
                display: flex;
                gap: 5px;
                padding: 6px 0;
                .form-title-img{
                    color: #555;
                    font-size: .9rem;
                }
               }
               .pop-img{
                position: absolute;
                top:0;
                left: 10%;
                bottom: 0;
                z-index: 111;
                height: 80%;
                width: 90%;
               }
               .img-file{
                border: none;
                background: red;
               }
            }
        }
        .formulaire-right{
            flex: 5;
            width: 100%;
            position: relative;
            .form-center{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .form-h2{
                    padding : 5px 0;
                    color: #5a5959;
                    font-weight: 300;
                    font-size: 1.1rem;
                    span{
                        color: red;
                        font-size: 1.1rem;
                    }
                }
                .form-rows{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    .form-row{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        width: 100%;
                        .label-form{
                            width: 100%;
                            font-size: .8rem;
                            color: #555;
                            display: flex;
                            align-items: center;
                            span{
                                color: red;
                                font-size: 1.1rem;
                            }
                        }
                        .input-form,.form-select{
                            border: 1px solid #d1d0d0;
                            width: 100%;
                            padding: 5px 10px;
                            width: 100%;
                            border-radius: 5px;
                            background: #f2f2f269;
                            outline: none;
                            &:focus{
                                border: 1px solid rgba(58, 88, 180, 0.781);
                            }
                            &::placeholder{
                                font-size: .7rem;
                                color: #949393;
                            }
                        }
                        .form-radio{
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }
                    }
                }
            }
            .form-btn{
                padding: 8px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                font-size: 1rem;
                background: royalblue;
                border-radius: 5px;
                color: #f2f2f2;
                cursor: pointer;
                transition: .5s;
                &:hover{
                    background: rgba(54, 85, 179, 0.979);
                }
                .form-icon{
                    font-size: 1rem;
                }
            }
        }
    }
}